<template>
  <div id="main-menu">
    <div class="row">
      <MenuItem v-for="(item, i) in Menu_items" v-bind:key="i" v-bind="item" />
    </div>
    <div class="row" id="td">
      <div id="challenge" style="transform: rotate(0);">
        <!--rotate(0) to contain the streched link, otherwise it goes everywhere-->
        <div>
          <img id="img1" src="/images/themes/ET/menu/fa-bolt.svg" />
        </div>
        <router-link id="name" class="fg-white" to="/solo">
          Today's Challenge
        </router-link>
      </div>
    </div>
    <portal to="footer-head">
      <h3>Maybe some text here i dont know what to put yet</h3>
    </portal>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuItem from "@/components/MenuItem.vue";

export default {
  name: "MainMenu",
  components: {
    MenuItem
  },
  data() {
    return {
      Menu_items: [
        {
          name: "Survival Kit",
          color: "primary",
          textcolor: "light",
          size: 4,
          image: "fa-life-ring.svg",
          link: "/survivalkit"
        },
        {
          name: "Peer Mentoring",
          color: "egshell",
          textcolor: "light",
          image: "fa-comments-o.svg",
          link: "/peermentoring"
        },
        {
          name: "Quiz Games",
          color: "nappe",
          textcolor: "light",
          image: "fa-trophy.svg",
          link: "/quizmenu"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#main-menu {
  @extend .text-right;
  width: 100vw;
  @extend .px-3;
  @extend .m-0;
  .row {
    @extend .pt-1;
  }
  #td {
    @extend .p-1;
    @extend .pb-2;
    @include media-breakpoint-up(sm) {
      height: 25vh;
    }
  }

  #challenge {
    @extend .p-3;
    @extend .media;
    border-radius: 0.5em 0.5em 0.5em 0.5em;
    @extend .bg-secondary;
    width: 100%;
    height: 15vh;
    @include media-breakpoint-up(sm) {
      height: 100%;
    }

    div {
      object-fit: contain;

      svg {
        width: 5vh;
        height: 5vh;
      }
      img {
        min-height: 3em;
        height: 12vh;
        object-fit: contain;
        @include media-breakpoint-up(sm) {
          height: 15vh;
          margin-bottom: 2em;
        }
      }
    }
    a {
      @extend .stretched-link;
      // @extend .text-light;
      @extend .font-weight-bold;
      @extend .media-body;
      margin-left: 0em;
      align-self: flex-end;
      color: white;
    }
  }
}
</style>
