<template>
  <form class="form-signin" action="login" v-on:submit.prevent.stop="login">
    <p v-if="msg">{{ msg }}</p>
    <p>
      Use demo@demo.com / demo as login / password
    </p>
    <label for="inputEmail" class="sr-only">Email address</label>
    <input
      type="email"
      id="inputEmail"
      class="form-control"
      placeholder="Email address"
      required
      autofocus
      v-model="username"
    />
    <label for="inputPassword" class="sr-only">Password</label>
    <input
      type="password"
      id="inputPassword"
      class="form-control"
      placeholder="Password"
      required
      v-model="password"
    />
    <div class="checkbox mb-3">
      <label> <input type="checkbox" value="remember-me" /> Remember me </label>
    </div>
    <button class="btn btn-lg btn-primary btn-block" type="submit">
      Sign in
    </button>
  </form>
</template>

<script>
import api from "@/services/api.js";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      msg: ""
    };
  },
  methods: {
    async login() {
      try {
        const response = await api.login(this.username, this.password);
        const location = response.headers.location;
        if (location !== "undefined") {
          await this.$store.dispatch("login", { location });
          this.$router.push("/");
        } else {
          console.log("empty location !");
          this.$router.push("/login");
        }
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;

  .checkbox {
    font-weight: 400;
  }
  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-control:focus {
    z-index: 2;
  }
  input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}</style
>>
