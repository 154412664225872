<template>
  <div class="text-center">
    <Avatar id="avatar" />
    <h1>{{ user.firstName }} {{ user.lastName }}</h1>
    <p>{{ user.jobPosition.name }} at {{ user.company.name }}</p>
    <p><b>email:</b> {{ user.email }}</p>
    <div v-if="user.isMentor">
      <img
        src="/images/themes/ET/grade.svg"
        style="height: 1.6em;"
        v-for="i in user.mentorLevel"
        v-bind:key="i"
      />
    </div>
    <blockquote>{{ user.description }}</blockquote>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";

export default {
  name: "user",
  components: {
    Avatar
  },
  data: function() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  }
};
</script>

<style scoped lang="scss">
blockquote {
  @extend .blockquote;
}

#avatar {
  height: 15em;
}
</style>
